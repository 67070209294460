<template>
  <el-dropdown trigger="click" :hide-on-click="false">
    <el-button type="primary" size="mini">
      全部导出<i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item>
          <el-date-picker
            size="mini"
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="YYYY-MM-DD"
           
            />
          <div class="block">
               <el-button type="primary" size="mini" @click="ExportSubmit">确定</el-button>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { reactive, toRefs,getCurrentInstance,onMounted } from "vue";
import dayjs from "dayjs"
export default {
  name: "exportButton",
  components: {},
  emits:['exportTimeChange'],
  setup(props,{emit}) {
    const data = reactive({
      time: null,
      vueExample:{}
    });

    onMounted(() => {
        data.vueExample = getCurrentInstance()
    })

    const ExportSubmit = () => {
        const {proxy} = data.vueExample
        if(data.time === null) {
            proxy.$message.error('请选择要导出的日期范围')
            return false
        }
        emit('exportTimeChange',handleDate(data.time))
        data.time = null

       
    }

    const handleDate = (timeList) => {
        let _arr = []
        timeList.forEach(item => {
            _arr.push(dayjs(item).format('YYYY-MM-DD'))
        })
        return _arr;
    }


    return {
      ...toRefs(data),
      ExportSubmit
    };
  },
};
</script>

<style lang="less" scoped>
.block{
    text-align: right;
}
</style>
